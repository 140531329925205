<!--
 * @Description: 备忘录类型
 * @Author: 琢磨先生
 * @Date: 2023-02-07 11:06:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-22 15:27:19
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button>
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" border>
      <el-table-column label="名称" prop="name" min-width="180"></el-table-column> 
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="600px"
    :close-on-click-modal="false"
    draggable
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="16">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
        <div class="help-block">使用后名称无法修改</div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      visibleDialog: false,
      title: "",
      tableData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     *
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.get(`seller/v1/note/type`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    onEdit(item) {
      this.title = item ? "修改" : "新增";
      this.form = {};
      this.form = Object.assign(
        {
          in_or_out: 0,
        },
        item
      );
      this.visibleDialog = true;
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/note/type/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("/seller/v1/note/type/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },
  },
};
</script>
  
  <style scoped>
.content_wrp {
  display: flex;
  flex-wrap: wrap;
}
.content_wrp .item {
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--el-border-color);
  padding: 15px 20px;
}
.content_wrp .item .name {
  margin-right: 20px;
}
</style>